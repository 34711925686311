import { useEffect } from 'react'

import { useRouter } from 'next/dist/client/router'

export default function LoaderPage() {
  const router = useRouter()
  const { asPath } = useRouter()

  useEffect(() => {
    if (asPath.startsWith('/campanhas/com-aval-e-garantia/')) {
      return router.push(asPath)
    }

    if (asPath.startsWith('/campanhas/com-aval/')) {
      return router.push(asPath)
    }

    if (
      (/\/campanhas\/\d+\//.test(asPath) && /\/campanhas\/\d+\/[A-Za-z0-9_.-]/.test(asPath)) ||
      (/\/minhas-indicacoes\/\d+\//.test(asPath) && /\/minhas-indicacoes\/\d+\/[A-Za-z0-9_.-]/.test(asPath)) ||
      (/\/meus-investimentos\/\d+\//.test(asPath) && /\/meus-investimentos\/\d+\/[A-Za-z0-9_.-]/.test(asPath)) ||
      (/\/assinaturas\/\d+\//.test(asPath) && /\/assinaturas\/\d+\/[A-Za-z0-9_.-]/.test(asPath))
    ) {
      return router.push('/')
    }

    if (asPath.startsWith('/campanhas')) {
      router.push(asPath)
    }

    if (asPath.startsWith('/meus-investimentos')) {
      router.push(asPath)
    }

    if (asPath.startsWith('/assinaturas')) {
      router.push(asPath)
    }

    if (asPath.startsWith('/minhas-indicacoes')) {
      router.push(asPath)
    }

    if (asPath.startsWith('/admin')) {
      router.push(asPath)
    }

    if (asPath.startsWith('/preencher-dados')) {
      router.push(asPath)
    }

    if (asPath.startsWith('/reset-de-senha')) {
      router.push(asPath)
    }

    if (asPath.startsWith('/perguntas-frequentes')) {
      router.push(asPath)
    }
  })

  return <></>
}
